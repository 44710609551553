import styled from "styled-components"

export const FooterWrapper = styled.div`
background:#000;
display: flex;
flex-direction:column;
align-items: center;
justify-content: space-between;
position: relative;
padding-top:35px;
max-width: 100%;
color:white;

.tider {
  a {
    font-size: .8rem;
    color:white;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color:#7fbd1b;
    }
  }
}

div {
  text-align:center;

  h3 {
    color: #7FBD1B; 
    font-family: 'Nunito', sans-serif;
    font-size:.8rem;
    letter-spacing:3px;
    text-transform:uppercase;
    margin: 0;
  }
  p {
    font-size:.85rem;
  }
}

.links {
  display:flex;
  flex-direction:column; 

  a {
    color: white;
    font-family:'Nunito', sans-serif;
    font-size:.85rem;
    height:50px;
   

    @media screen and (min-width: 768px) {
    height:20px;
    }
  }
}

@media screen and (min-width: 768px) {
    
  flex-direction:row;
  align-items:start;
  padding-top:70px;

  div {
    flex: 1 1 0;
    width: 0;
    
    
  }

  .links {
    
    a {
      
      
      transition:.3s;
        :hover {
          color:#7FBD1B;
        }
      }
    }
  }
}
`
export const FooterWrapper2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;
  flex-direction:row;
  position: relative;
  background:#000;
  color:white;
  align-items:center;

  .sociala-medier {
    display:flex;
    flex-directiion:row;
    position:relative;
    width:100%;
    justify-content:center;

    a {
      color:#7FBD1B; 
      transition:.3s;
      font-size:2rem;
      margin:10px;
      height:50px;
      text-align: center;
      :hover {
        color:white;
      }
      p {
        opacity:0;
        height:1px;
        margin:0;
      }
    }
  }


@media screen and (min-width: 768px) {

  
  justify-content: center;
  

  .sociala-medier {
    width:80%;
    

    :before {
      left:10%;
      bottom: 30px;
    }
    :after {
      right:10%;
      bottom: 30px;
    }

    a {
      
      height:auto;
      :hover {
        color:white;
      }
     
    }

  }
}
`

export const FooterWrapperSociala = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;
  flex-direction:column;
  position: relative;
  background:#000;
  color:white;
  align-items:center;

  .sociala-medier {
    display:flex;
    flex-directiion:row;
    position:relative;
    width:100%;
    justify-content:center;
    
    a {
      color:#7FBD1B; 
      transition:.3s;
      font-size:2rem;
      margin:10px;
      height:50px;
      text-align: center;
      :hover {
        color:white;
      }
      p {
        opacity:0;
        height:1px;
        margin:0;
      }
    }
  }


@media screen and (min-width: 768px) {

  
  justify-content: center;
  

  .sociala-medier {
    width:80%;
    

    :before {
      left:10%;
      bottom: 30px;
    }
    :after {
      right:10%;
      bottom: 30px;
    }

    a {
      
      height:auto;
      :hover {
        color:white;
      }
     
    }

  }
}
`

export const FooterBottom = styled.div`

background:#000;
padding-top:35px;
padding-bottom:35px;
h3 {
  text-align:center;
  margin:0;
  color:#7FBD1B;
  letter-spacing:3px;
  font-family: 'Nunito', sans-serif;
}
.starholder { 
  display:flex;
  justify-content:space-around;
  flex-direction:column;
  align-items:center;
  div {
    width:80%;
    text-align:center;
    svg {
      color:#7FBD1B;
      
    }
    p {
     color:white; 
     font-size:.8rem;
    }
  }

}

@media screen and (min-width: 768px) {



.starholder {
  flex-direction:row;
  div {
    width:20%;
  }

}

}

`

